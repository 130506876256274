import React, { useEffect, useState } from "react";
import { useCommon } from "store";
import portageurApi from "request/apis/portageur";
import styles from "./index.module.css";
import IconFont from "components/IconFont";
import { Button, Switch, Spin, message } from "antd";
import ismobilejs from "ismobilejs";
import { TABLE_INFO } from "config/constant";
import { Table } from "request/apis/portageurTypes";
import classNames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";
import gTag from "utils/gTag";

const USE_CODE = "NEW24";

export const StripePricingTable = () => {
  const {
    subscription: { isSubscribed },
  } = useCommon();
  const [tableData, setTableData] = useState<Table>();
  const [checkedStatus, setCheckedStatus] = useState<boolean>(false);
  const isMobile = ismobilejs(window.navigator).any;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://js.stripe.com/v3/pricing-table.js';
  //   script.async = true;
  //   document.body.appendChild(script);
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  useEffect(() => {
    getTableInfo();
    setCheckedStatus(false);
  }, []);

  const getTableInfo = async () => {
    setIsLoading(true);
    try {
      const data = await portageurApi.getPricingTable();
      setTableData(data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const type = checkedStatus ? "Monthly" : "Yearly";

  useEffect(() => {
    gTag.switchPlan(type);
  }, [type]);

  const onSubscribe = async price_id => {
    const newWindow = isMobile ? window.open() : null;
    try {
      const res = await portageurApi.getSubscribe({ price_id });
      if (isMobile) {
        newWindow.location = res.data;
      } else {
        window.open(res.data, "_self");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {!isSubscribed ? (
        <div className={styles.header}>
          <h1>Trial Completed</h1>
          <p>To continue, subscribe for uninterrupted access.</p>
        </div>
      ) : (
        <div className={styles.header}>
          <h1>To continue, subscribe for uninterrupted access.</h1>
        </div>
      )}
      {/* {
      React.createElement('stripe-pricing-table', {
        'pricing-table-id': envVars.stripePricingTableId,
        'publishable-key': envVars.stripePublishableKey,
        'client-reference-id': customerId,
        'customer-email': customerEmail,
      })
    } */}
      <div className={styles.switchContainer}>
        <div
          className={classNames(
            styles.yearly,
            checkedStatus ? styles.plan : styles.checkedText,
          )}>
          Yearly &nbsp;
          <span className={styles.saveText}>(Save up to 15%)</span>
        </div>

        <Switch
          checked={checkedStatus}
          className={styles.switch}
          onChange={status => setCheckedStatus(status)}
        />
        <div className={checkedStatus ? styles.checkedText : styles.plan}>
          Monthly
        </div>
      </div>
      <Spin spinning={isLoading}>
        <div className={styles.cardContainer}>
          {tableData?.data?.[type]?.map(it => {
            // const limitedTimeOffer = it.tier === "Basic" && !checkedStatus;
            const limitedTimeOffer = false;

            return (
              <div className={styles.priceCard} key={it.tier}>
                <div className={styles.cardTop}>
                  {limitedTimeOffer && (
                    <div className={styles.limitedTimeOffer}>
                      <div className={styles.note}>Limited-Time Offer!</div>
                      <div>
                        Save 24% - Use code:{" "}
                        <CopyToClipboard
                          text={USE_CODE}
                          onCopy={() => {
                            message.success("Copied");
                          }}>
                          <span style={{ cursor: "pointer" }}>{USE_CODE}</span>
                        </CopyToClipboard>
                      </div>
                    </div>
                  )}
                  {it.tier === "Premium" && checkedStatus && (
                    <div className={styles.cardCap}>Popular</div>
                  )}
                  <div className={styles.priceTitle}>{it.tier}</div>
                  <div className={styles.info}>
                    {" "}
                    {TABLE_INFO[it.tier]?.intro}
                  </div>
                  <div>
                    {it.tier !== "Enterprise" ? (
                      <>
                        {limitedTimeOffer ? (
                          <div className={styles.limitedPrice}>
                            <div className={styles.currentPrice}>US$19</div>
                            <div className={styles.originalPrice}>
                              US${it.price}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.price}>US${it.price}</div>
                        )}
                        <div className={styles.subtitle}>
                          /month {!checkedStatus && "(Yearly, billed monthly)"}
                        </div>
                      </>
                    ) : (
                      <div
                        className={styles.price}
                        style={{ color: "#808194", marginBottom: 27 }}>
                        Contact Us
                      </div>
                    )}
                  </div>
                  <Button
                    type="primary"
                    className={styles.button}
                    onClick={() => {
                      if (it.tier === "Enterprise") {
                        gTag.clickContact(type);
                        // window.open("mailto:info@portageur.ai", "_blank");
                        window.open("https://portageur.ai/schedule");
                      } else {
                        gTag.clickPlan(it.tier, type);
                        onSubscribe(it.price_id);
                      }
                    }}>
                    {it.tier === "Enterprise"
                      ? "Book Demo"
                      : limitedTimeOffer
                      ? "Subscribe & Save 24%"
                      : "Subscribe"}
                  </Button>
                </div>
                <div className={styles.cardBottom}>
                  <div className={styles.features}>
                    {TABLE_INFO[it.tier]?.feat.map(item => (
                      <div className={styles.feature} key={item}>
                        <IconFont
                          type="tick-circle"
                          style={{
                            fontSize: 16,
                            color: "#3A404D",
                            marginRight: 6,
                            marginTop: 3,
                          }}></IconFont>
                        <div dangerouslySetInnerHTML={{ __html: item }}></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Spin>
    </div>
  );
};
