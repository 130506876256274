import React, { useContext, useMemo, useRef, useEffect, useState } from 'react';
import styles from './index.module.css';
import Container from 'components/Container';
import { Button, Typography, Grid } from 'antd';
import PromptInput from 'components/PromptInput';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  EditorState,
  LexicalEditor,
  // ParagraphNode,
  TextNode,
} from 'lexical';
import {
  deepClearNodes,
  getReactNodesFromEditorState,
  getStringFromEditorState,
} from 'utils/lexical';
import { useAsync } from 'hooks/useAsync';
import portageurApi from 'request/apis/portageur';
import {
  Chat,
  ChatStatus,
  ReferencePDF,
  ShowQrank,
  TextElement,
  // InvestmentTemplate,
} from 'request/apis/portageurTypes';
import {
  Message,
  MessageSender,
  OnReference,
} from 'components/MessagesRenderer/types';
import MessagesRenderer from 'components/MessagesRenderer';
import classNames from 'classnames';
import { useQuery } from 'hooks/useQuery';
import SuggestionList from 'components/SuggestionList';
import {
  $createPlaceholderNode,
  PlaceholderNode,
} from 'components/PromptInput/nodes/PlaceholderNode';
import PdfDisplay from 'components/PdfDisplay';
import { SidebarContext, SubscriptionModalContext } from 'components/Layout';
import { useNavigate } from 'react-router-dom';
import MarkdownRenderer from 'components/MarkdownRenderer';
import IconFont from 'components/IconFont';
import { getRandomElements } from 'utils';
import Footnote from '../Footnote';
import { useCommon } from 'store';
// import { set } from "lodash";
import { useRequest, useSize } from 'ahooks';
import { CompanyNode } from 'components/PromptInput/nodes/CompanyNode';
import useChatContext, { ChatContext } from './useContext';
import BottomBut from 'components/BottomBut';
import { parse } from 'query-string';
import { SubscriptionInfoModal } from 'components/SubscriptionInfoModal';
import ismobilejs from 'ismobilejs';
import _ from 'lodash';
import useStream from './useStream';
import { FRONTEND_MESG, SOURCE } from 'config/constant';

const { useBreakpoint } = Grid;
const { Title } = Typography;

interface ChatProps {
  initialMessages?: Message[];
  initialSessionId?: string;
  initialRequestId?: number;
  initialTid?: string;
  hideNewTopicButton?: boolean;
  onInputChange?: (editorState: EditorState | null) => void;
  insertQrank?: ShowQrank;
}

interface AskParams {
  source?: number[];
  prompt: string;
  sessionId?: string;
  requestId?: number;
  retry?: number;
  conversationId?: string;
  tid?: string;
}

const Chat: React.FC<ChatProps> = ({
  initialMessages,
  initialRequestId,
  initialSessionId,
  initialTid,
  hideNewTopicButton,
  onInputChange,
  insertQrank,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [ investmentTid, setInvestmentTid ] = useState(initialTid || '');
  const [ messages, setMessages ] = React.useState<Message[]>(
    initialMessages || [],
  );
  const sessionId = useRef<string | null>(initialSessionId || null);
  const conversationId = useRef<string | null>(null);
  const currentRequestId = useRef<number>(initialRequestId || -1);
  const lexicalEditor = useRef<LexicalEditor | null>(null);
  const [ isPooling, setIsPooling ] = useState(false);
  const [ disableNewTopic, setDisableNewTopic ] = React.useState(false);
  const [ retryCount, setRetryCount ] = React.useState(0);
  const messageRef = useRef<HTMLDivElement>(null);
  const pdfRef = useRef<HTMLDivElement>(null);
  const bottomButRef = useRef<any>(null);
  const [ pdf, setPdf ] = React.useState<ReferencePDF>();
  const [ subscriptionInfoModalVisible, setSubscriptionInfoModalVisible ] =
    useState(false);

  const subscriptionModal = useContext(SubscriptionModalContext);

  const { user } = useCommon();

  const navigate = useNavigate();

  const sidebar = useContext(SidebarContext);

  const queryParams = useQuery();

  const chatQuery = useAsync<Chat>();
  const suggestedPromptsQuery = useAsync<string[]>();
  const { data: investmentTemplates = [] } = useRequest(() =>
    portageurApi.getInvestmentTemplates().then(res => res.data),
  );

  const [ openReference, setOpenReference ] = React.useState<TextElement | null>(
    null,
  );
  const [ source, setSource ] = useState(SOURCE.map(v => v.code));

  const { getStream } = useStream({
    setMessages,
    setIsPooling,
    setDisableNewTopic,
    chatQuery,
    currentRequestId,
    sessionId,
    conversationId,
    isInvestment: !!investmentTid,
  });

  const inner = React.useRef(null);
  const width = useSize(inner)?.width ?? 0;

  const bottomRef = useRef<HTMLDivElement>(null);

  const isMobile = ismobilejs(window.navigator).any;

  const scrollToBottom = (distance = 0, timeout = 0, behavior = 'smooth') => {
    setTimeout(() => {
      if (bottomRef.current) {
        if (distance) {
          const dom = document.querySelector(
            openReference ? '.chat-container-msg' : '.ant-layout-content',
          );
          dom && (dom.scrollTop = distance);
        } else {
          bottomRef.current.scrollIntoView({ behavior });
        }
      }
    }, timeout);
  };

  // useEffect(() => {
  //   if (!openReference) scrollToBottom();
  // }, [ openReference ]);

  useEffect(() => {
    if (width === 0) return;
    if (width < 640) {
      sidebar.setIsOpen(false);
    } else if (width >= 640) {
      sidebar.setIsOpen(true);
    }
  }, [ window.innerWidth ]);

  useEffect(() => {
    const query = parse(location.search);
    if (query?.plan) {
      setSubscriptionInfoModalVisible(true);
    }
  }, []);

  const screens = useBreakpoint();

  const { qrank, qrankData, runQrank, initialQrank } =
    useChatContext(insertQrank);

  const clearEditor = () => {
    lexicalEditor.current?.update(() => {
      // Get the RootNode from the EditorState
      const root = $getRoot();

      // Make sure there is no content in the root
      deepClearNodes(root);
      root.clear();
      // Create a new ParagraphNode
      const paragraphNode = $createParagraphNode();

      // Append new ParagraphNode to the root
      root.append(paragraphNode);

      // Select the paragraph node to avoid creating a new one
      paragraphNode.select();
    });
  };

  const sendMessage = async (newMessage: Message, companies?: string[]) => {
    setMessages(prev => [ ...prev, newMessage ]);
    setDisableNewTopic(true);
    scrollToBottom(0, 500, 'instant');

    // Disable the editor
    // lexicalEditor.current?.setEditable(false);
    clearEditor();

    if (companies && !initialRequestId && !qrank.show) {
      runQrank(companies, messages.length + 1);
    }

    async function askQuestion() {
      const params: AskParams = {
        prompt: newMessage.message,
        requestId: newMessage.requestId < 0 ? undefined : newMessage.requestId,
        sessionId: sessionId.current || undefined,
      };
      if (newMessage.retry) {
        params.retry = 1;
        params.conversationId = newMessage.conversationId || undefined;
      }
      if (investmentTid) {
        params.tid = investmentTid;
      } else {
        params.source = source.filter(v => v);
      }
      const res = await portageurApi[
        investmentTid ? 'askInvestmentQuestion' : 'askQuestion'
      ](params);

      // setMessages(prev => {
      //   const userIndex = prev.findLastIndex(item => item.sender === "user");
      //   const userMessage = prev[userIndex];
      //   if (userMessage)
      //     userMessage.conversationId = res.data.conversation_id || undefined;
      //   return prev;
      // });

      sessionId.current = res.data.session_id;
      conversationId.current = res.data.conversation_id;
      currentRequestId.current = res.data.request_id;

      return res.data;
    }

    await chatQuery.runAsync(askQuestion());

    scrollToBottom(0, 500, 'instant');
    getStream();
  };

  const onSubmit = (editorState: EditorState | null) => {
    if (!user?.subscription?.isSubscribed) {
      subscriptionModal.setIsOpen(true);
      return;
    }
    // If the editor is empty, do nothing
    // If the chat is pending, do nothing to make sure we don't send multiple requests
    if (!editorState || [ 'pending' ].includes(chatQuery.status) || isPooling) {
      return;
    }

    const entries = editorState._nodeMap.entries();

    let validPlaceholders = true;
    let hasCompanyNode = false;
    const companies: string[] = [];
    let hasText = false;

    editorState.read(() => {
      for (const [ , value ] of entries) {
        if (value instanceof CompanyNode) {
          hasCompanyNode = true;
          companies.push(value.__code);
        }
        // Check if there are any not empty placeholders left
        if (value instanceof PlaceholderNode && !value.isEmpty()) {
          validPlaceholders = false;
        }
        if (value.__type === 'text' && value.__text.trim().length > 0) {
          hasText = true;
        }
      }
    });

    if (currentRequestId.current === -1 && !hasCompanyNode) {
      const newMessage = FRONTEND_MESG.CompanyCode as Message;
      setMessages(prev => [ ...prev, newMessage ]);
      return;
    }

    if (!validPlaceholders) {
      const newMessage = FRONTEND_MESG.Placeholder as Message;
      setMessages(prev => [ ...prev, newMessage ]);
      scrollToBottom(0, 500, 'instant');
      return;
    }

    if (currentRequestId.current !== -1 && investmentTid) {
      const newMessage = FRONTEND_MESG.StartNew as Message;
      setMessages(prev => [ ...prev, newMessage ]);
      scrollToBottom(0, 500, 'instant');
      clearEditor();
      return;
    }

    if (companies.length > 1 && investmentTid) {
      const newMessage = FRONTEND_MESG.OneCompany as Message;
      setMessages(prev => [ ...prev, newMessage ]);
      scrollToBottom(0, 500, 'instant');
      return;
    }

    if (hasText && investmentTid) {
      const newMessage = FRONTEND_MESG.OneCompany as Message;
      setMessages(prev => [ ...prev, newMessage ]);
      scrollToBottom(0, 500, 'instant');
      return;
    }

    const editorString = getStringFromEditorState(editorState);

    // Create a new message
    const newMessage = {
      requestId: currentRequestId.current,
      message: editorString,
      html: getReactNodesFromEditorState(editorState),
      sender: 'user' as MessageSender,
      status: 'answer' as ChatStatus,
    };

    // 在输入新问题时  将上一个如果出现重试次数给初始化
    setRetryCount(0);

    return sendMessage(newMessage, companies);
  };

  const onResend = () => {
    // 获得当前重试的用户输入信息
    const userIndex = messages.findLastIndex(item => item.sender === 'user');
    const userMessage = messages[userIndex];

    if (!userMessage) {
      console.error('No user message to resend');
      return;
    }

    if (userMessage && !conversationId.current) {
      setMessages(prev => {
        const _pre = [ ...prev ];
        _pre[prev.length - 1].status = 'error';
        return _pre;
      });
      console.error('No conversationId to resend');
      return;
    }

    // 在重试时移除这条重试的提示
    setMessages(prev => prev.slice(0, userIndex));

    const newMessage = {
      ...userMessage,
      requestId: currentRequestId.current,
      conversationId: conversationId.current,
      status: 'answer' as ChatStatus,
    };
    if (userMessage.requestId > -1) {
      newMessage.retry = 1;
    }
    // 重试计数
    setRetryCount(retryCount + 1);

    // Send the message again
    sendMessage(newMessage);
  };

  const onFeedback = async ({
    feedbackId,
    requestId,
  }: {
    feedbackId: number;
    requestId: number;
  }) => {
    if (!sessionId.current) {
      return;
    }

    // Update the message status
    function setMessageFeedbackStatus(
      status: 'pending' | 'resolved' | 'rejected',
    ) {
      setMessages(prev => {
        const copy = [ ...prev ];
        const message = copy.find(
          message =>
            message.requestId === requestId && message.sender === 'bot',
        );
        if (message) {
          message.feedback = {
            feedbackId,
            status,
          };
        }
        return copy;
      });
    }

    // Set the message status to pending
    setMessageFeedbackStatus('pending');

    // Send the feedback
    await portageurApi
      .sendFeedback({
        feedbackId,
        requestId,
        sessionId: sessionId.current,
      })
      .then(() => {
        setMessageFeedbackStatus('resolved');
      })
      .catch(() => {
        setMessageFeedbackStatus('rejected');
      });
  };

  const onReference: OnReference = async (reference, pdf) => {
    const url = Object.keys(pdf || {})[0];
    const highlights = pdf?.[url] ?? [];

    setPdf({
      url,
      highlights: _.cloneDeep(highlights),
    });

    // sidebar.setIsOpen(false);
    setOpenReference(reference);
  };

  const onReferenceClose = () => {
    setOpenReference(null);
    if (screens.lg) sidebar.setIsOpen(true);
  };

  const onSuggestionClick = ({ suggestion, tid }) => {
    setInvestmentTid(tid);
    clearEditor();

    if (!user?.subscription?.isSubscribed) {
      subscriptionModal.setIsOpen(true);
      return;
    }

    if (tid) {
      const newMessage = FRONTEND_MESG.RecipeQuestion as Message;
      setMessages(prev => [ ...prev, newMessage ]);
      return;
    }

    lexicalEditor.current?.update(() => {
      // Get the RootNode from the EditorState
      const root = $getRoot();

      // Make sure there is no content in the root
      deepClearNodes(root);
      root.clear();
      // Create a new ParagraphNode
      const paragraphNode = $createParagraphNode();

      let text = '';
      let matchesCount = 0;
      let selectedNode: TextNode | null = null;

      // Iterate over chars in the suggestion
      for (const char of suggestion) {
        text += char;
        // Check if the char is a placeholder
        const matches = text.match(/\[(.*?)\]/g);
        if (matches) {
          const match = matches[0];
          // Remove the placeholder from the text
          const computedText = text.replace(match, '');
          // Create a new TextNode with the text
          const textNode = $createTextNode(computedText);
          // Append the text node to the paragraph
          paragraphNode.append(textNode);
          text = '';
          // Create a new PlaceholderNode
          const placeholderType =
            PlaceholderNode.getPlaceholderTypeFromString(match);
          const placeholderNode = $createPlaceholderNode(
            match,
            match,
            placeholderType || 'company',
          );
          // If is the first placeholder, select it
          const placeholderTextNode = $createTextNode(
            matchesCount === 0 ? '@' : String.fromCharCode(65 + matchesCount),
          );
          if (matchesCount === 0) {
            selectedNode = placeholderTextNode;
          }
          // Append the placeholder to the paragraph
          placeholderNode.append(placeholderTextNode);
          paragraphNode.append(placeholderNode);
          matchesCount++;
        }
      }

      // If there is still text left, create a new TextNode
      if (text) {
        const textNode = $createTextNode(text);
        paragraphNode.append(textNode);
      }

      // Finally, append the paragraph to the root
      root.append(paragraphNode);
      selectedNode?.select();
    });
  };

  React.useEffect(() => {
    if (
      chatQuery.data?.type === 'error' ||
      chatQuery.data?.type === 'answer' ||
      chatQuery.status === 'rejected'
    ) {
      // Make sure to stop pooling when the chat is resolved or rejected, and enable the editor
      setIsPooling(false);
      lexicalEditor.current?.setEditable(true);
      setDisableNewTopic(false);
      setMessages(prev => [
        ...prev.filter(item => item.status !== 'progress'),
      ]);
    }

    // 对话框中非订阅使用超过阈值的限制
    // code -101 -102 -103 -104 -105
    // 400是错误展示
    // 500是网络错误展示
    if (
      chatQuery.status === 'rejected' &&
      /\-101|102|103|104|105|400|403|500/.test(chatQuery.error?.code)
    ) {
      const newMessage = {
        message: chatQuery.error?.msg,
        status: 'limit' as ChatStatus,
        sender: 'bot' as MessageSender,
      } as Message;

      setDisableNewTopic(false);
      setMessages(prev => [
        ...prev.filter(item => item.status !== 'progress'),
        newMessage,
      ]);
    }

    // 超时时对话框中展示和网络错误展示
    // 超时code -201
    if (
      chatQuery.status === 'rejected' &&
      /\-201/.test(chatQuery.error?.code)
    ) {
      const newMessage = {
        message: chatQuery.error?.msg,
        status: 'retry' as ChatStatus,
        sender: 'bot' as MessageSender,
      } as Message;

      setDisableNewTopic(false);
      setMessages(prev => [
        ...prev.filter(item => item.status !== 'progress'),
        newMessage,
      ]);
    }
  }, [ chatQuery.data, chatQuery.status ]);

  const caseId = queryParams.get('caseId');

  React.useEffect(() => {
    // if (!user?.subscription?.isSubscribed) {
    //   return;
    // }
    // Get the suggested prompts
    async function getSuggestedPrompts() {
      const res = await portageurApi.getSuggestions({
        caseId: caseId ? Number(caseId) : undefined,
      });

      return res.data;
    }

    suggestedPromptsQuery.runAsync(getSuggestedPrompts());
  }, [ caseId, suggestedPromptsQuery.runAsync ]);

  React.useEffect(() => {
    // Stop pooling when the component unmounts
    return () => {
      setIsPooling(false);
    };
  }, []);

  React.useEffect(() => {
    // Update the messages when the chatQuery changes
    if (chatQuery.data) {
      const text =
        typeof chatQuery.data.content === 'string'
          ? chatQuery.data.content
          : chatQuery.data.content.answer;

      const references =
        typeof chatQuery.data.content === 'string'
          ? []
          : chatQuery.data.content.text_elements;

      const pdf =
        typeof chatQuery.data.content === 'string'
          ? []
          : chatQuery.data.content.reference || [];

      const newMessage = {
        requestId: chatQuery.data.request_id,
        message: text,
        html: <MarkdownRenderer markdown={text} />,
        status: chatQuery.data.type,
        pdf,
        references,
        sender: 'bot' as MessageSender,
        notification: chatQuery.data?.notification,
      };

      setMessages(prev => {
        const lastItem = prev[prev.length - 1];
        if (lastItem && lastItem.sender === 'user') {
          return [ ...prev, newMessage ];
        }
        return [
          ...prev.slice(0, prev.length - 1),
          { ...lastItem, ...newMessage },
        ];
      });

      chatQuery.data.type === 'answer' &&
        setTimeout(() => {
          bottomButRef.current?.autoScrollBottom?.(true);
        }, 300);
    }
  }, [ chatQuery.data ]);

  const onNewTopic = () => {
    // Reset the chat
    setIsPooling(false);
    sessionId.current = null;
    currentRequestId.current = -1;
    conversationId.current = null;
    initialQrank();
    setRetryCount(0);
    setMessages([]);
    setOpenReference(null);
    navigate('/library-chat');
    clearEditor();
    lexicalEditor.current?.setEditable(true);
    setInvestmentTid('');
  };

  const randomSuggestedPrompts = useMemo(() => {
    if (!suggestedPromptsQuery.data || !investmentTemplates.length) {
      return [];
    }
    // Get random elements from the suggested prompts
    return [
      {
        suggestion: investmentTemplates[0].name,
        tid: investmentTemplates[0].tid,
      },
      ...getRandomElements(suggestedPromptsQuery.data, 3).map(str => ({
        suggestion: str,
      })),
    ];
  }, [ suggestedPromptsQuery.data, investmentTemplates ]);

  return (
    <ChatContext.Provider
      value={{
        qrank: qrankData,
        isInvestment: !!investmentTid,
        conversationId: conversationId.current as string,
        source, setSource: (v: number[]) => { setSource(v); },
        disabledShowSource: isPooling || !!investmentTid,
      }}
    >
      <SubscriptionInfoModal
        isOpen={subscriptionInfoModalVisible}
        onClose={() => setSubscriptionInfoModalVisible(false)}
      />
      {Boolean(messages.length) && !hideNewTopicButton && (
        <Button
          type={screens.lg ? 'primary' : 'text'}
          disabled={disableNewTopic}
          size={screens.lg ? 'middle' : 'large'}
          onClick={onNewTopic}
          className={classNames(
            styles.newTopicButton,
            sidebar.isOpen && styles.newTopicButtonSideOpen,
          )}
          icon={<IconFont type="add" />}
        >
          {screens.md && 'New Topic'}
        </Button>
      )}
      <div
        className={classNames(
          styles.container,
          openReference && styles.containerOpenReference,
          'chat-container',
        )}
        style={{ height: isMobile ? '100%' : 'fit-content' }}
      >
        <BottomBut
          ref={bottomButRef}
          messageRef={messageRef}
          scrollToBottom={scrollToBottom}
          openReference={openReference as TextElement}
          right={
            window.innerWidth > 1858
              ? sidebar.isOpen
                ? '44%'
                : '50%'
              : (pdfRef?.current?.offsetWidth as number) + 10
          }
        />
        <Container
          width="sm"
          className={classNames(
            styles.innerContainer,
            openReference && styles.innerContainerOpenReference,
          )}
        >
          <div className={styles.wrapper} ref={inner as any}>
            <div
              className={classNames(
                styles.textWrapper,
                messages.length && styles.hideTextWrapper,
              )}
            >
              <Title
                style={{
                  textAlign: 'center',
                  marginBottom: 12,
                  color: '#3A404D',
                }}
                level={1}
              >
                Welcome! Chat with Librarian
              </Title>
              <div
                style={{
                  fontSize: 15,
                  fontWeight: 400,
                  color: '#808194',
                }}
              >
                {user?.subscription?.tier === 'trial' ? (
                  <span>
                    Research one company, or compare multiple companies. <br />
                    Your 7-day trial includes 5 requests per day.
                  </span>
                ) : (
                  <span>
                    Research one company, or compare multiple companies.
                  </span>
                )}
              </div>
            </div>
            <div
              className={classNames(
                styles.contentWrapper,
                openReference && styles.contentWrapperOpenReference,
              )}
            >
              <div
                // style={{ marginTop: "auto" }}
                className={classNames(
                  styles.msgContainer,
                  'chat-container-msg',
                )}
              >
                {Boolean(messages.length) && (
                  <MessagesRenderer
                    messages={messages}
                    onFeedback={onFeedback}
                    onReference={onReference}
                    onResend={onResend}
                    retryCount={retryCount}
                    qrank={qrank}
                    ref={messageRef}
                    bottomRef={bottomRef}
                    autoScrollBottom={bottomButRef.current?.autoScrollBottom}
                    isInvestment={!!investmentTid}
                  />
                )}

                {!messages.length && Boolean(randomSuggestedPrompts.length) && (
                  <SuggestionList
                    suggestions={randomSuggestedPrompts}
                    className={styles.suggestionList}
                    onSuggestionClick={onSuggestionClick}
                  />
                )}
                <div className={styles.editor}>
                  <PromptInput
                    onChange={onInputChange}
                    ref={lexicalEditor}
                    onSubmit={onSubmit}
                    disableNewTopic={disableNewTopic}
                    investmentTid={investmentTid}
                    openReference={openReference}
                  />
                  <Footnote />
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className={styles.pdfContainer} ref={pdfRef}>
          {openReference && (
            <>
              <Button
                shape="circle"
                className={styles.button}
                icon={<IconFont type="square-right" />}
                onClick={onReferenceClose}
              />
              {pdf && pdf.url ? (
                <PdfDisplay
                  url={pdf.url}
                  highlights={pdf.highlights}
                  title={openReference.name || ''}
                  enableFullscreen={screens.lg}
                />
              ) : (
                <div className={styles.notFound}>
                  <Typography.Text className={styles.empty}>
                    The reference link is no longer available. Rephrase your
                    question for an updated reference.
                  </Typography.Text>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </ChatContext.Provider>
  );
};

export default Chat;
