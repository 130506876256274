import React from "react";
import styles from "./index.module.css";
import IconFont from "components/IconFont";
import { Button } from "antd";
import { WELCOME_TIP } from "config/constant";
import ismobilejs from "ismobilejs";

interface TrialTipsProps {
  setOpenTrialTips: (open: boolean) => void;
  onClose: () => void;
  name: string;
}

const TrialTips: React.FC<TrialTipsProps> = ({
  setOpenTrialTips,
  onClose,
  name,
}) => {
  const isMobile = ismobilejs(window.navigator).any;

  return (
    <div className={styles.container}>
      <div className={styles.bigTitle}>Welcome, {name} !</div>
      <div className={styles.title}>Your 7-day trial is now activated</div>
      {WELCOME_TIP?.map(item => (
        <div
          className={styles.point}
          key={item.icon}
          style={{ width: isMobile ? "90%" : "auto" }}>
          <IconFont
            type={item.icon}
            style={{ fontSize: 60, marginRight: 12 }}
          />
          <div>
            <div className={styles.info}>{item.title}</div>
            <div
              className={styles.details}
              style={{ width: isMobile ? "100%" : 326 }}>
              {" "}
              {item.content}{" "}
            </div>
          </div>
        </div>
      ))}
      <Button
        className={styles.btn}
        onClick={() => {
          onClose();
          setTimeout(() => {
            setOpenTrialTips(false);
          }, 1000);
        }}>
        Got it
      </Button>
      <span
        className={styles.remind}
        onClick={() => {
          setOpenTrialTips(false);
        }}>
        Access Premium Features
      </span>
    </div>
  );
};

export default TrialTips;
